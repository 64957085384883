<template>
  <div class="areaDetail">
    <!-- 日历弹出层 -->
    <van-popup v-model="show" position="top" :style="{ height: '80%' }">
      <van-calendar
        v-model="show"
        style="z-index: 10000"
        color="#5E5AFF"
        :show-mark="false"
        :show-title="false"
        :poppable="false"
        :show-confirm="true"
        position="top"
        @select="select"
        @confirm="onConfirm"
      />
    </van-popup>

    <!-- 选择日期 -->
    <div
      class="topBox"
      @click.stop="openCalendar"
      v-if="areaDetail.bookingType == 1"
    >
      <img class="timeIocn" src="./img/time.png" alt="" />
      <div class="date">{{ this.nowDay }} {{ this.nowWeek }}</div>
      <img class="arrow" src="./img/time_arrow.png" alt="" />
    </div>
    <!-- 图片轮播 -->
    <div class="activityDetail-t">
      <div class="introImg">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <div
            class="oSwiper"
            v-for="(item, index) in areaDetail.pictureBeanList"
            :key="index"
          >
            <van-swipe-item><img :src="item.url" alt="" /></van-swipe-item>
          </div>
        </van-swipe>
      </div>
    </div>
    <!-- 场次详情 -->
    <div class="detailBox">
      <div class="name">{{ areaDetail.name }}</div>
      <div class="equip" v-html="areaDetail.equip"></div>
      <div class="numBox">
        <img src="./img/menNum.png" alt="" />
        <span>{{ areaDetail.count }}人</span>
      </div>
      <div class="infoLine">
        <div>场馆类型：</div>
        <span>{{ placeType }}</span>
      </div>
      <div class="infoLine" v-if="areaDetail.area">
        <div>场馆面积：</div>
        <span>{{ areaDetail.area }}</span>
      </div>
      <div class="infoLine" v-if="areaDetail.address">
        <div>场馆地址：</div>
        <span>{{ areaDetail.address }}</span>
      </div>
      <div class="infoLine" v-if="areaDetail.bankingHours">
        <div>营业时间：</div>
        <span v-html="areaDetail.bankingHours"></span>
      </div>
      <div class="infoLine" v-if="areaDetail.contactPerson">
        <div>场地管理员：</div>
        <div class="phoneBox" @click="callPeople()">
          <span>{{ areaDetail.contactPerson }}</span>
          <img src="./img/phone.png" alt="" />
        </div>
      </div>
      <div class="cdjs" v-if="areaDetail.spaceDescription">场地介绍：</div>
      <div
        class="cdjs2"
        v-if="areaDetail.spaceDescription"
        v-html="areaDetail.spaceDescription"
      ></div>
    </div>
    <!-- 拉起选择预约场次弹框按钮 -->
    <div class="btnBox">
      <div class="box" @click.stop="showChoseSessios()">立即预约</div>
    </div>
    <!-- 底部百合子 -->
    <div class="bottomBox"></div>
    <!-- 选择预约场次遮罩层 -->
    <van-popup v-model="popupShow" position="bottom" :style="{ height: '80%' }">
      <div class="popup_box">
        <div class="popupTopBox">
          <div class="name">{{ areaDetail.name }}</div>
          <div class="equip" v-html="areaDetail.equip"></div>
          <div class="numBox">
            <img src="./img/menNum.png" alt="" />
            <span>{{ areaDetail.count }}人</span>
          </div>
        </div>
        <!-- 场次列表 -->
        <div class="bottom_box">
          <div
            :class="['item', { item_bg_change: item.isSelect }]"
            v-for="(item, index) in sessionsList"
            :key="index"
          >
            <div
              style="width: 100%; height: 100%"
              @click.stop="selectSession(item, index)"
            >
              <div class="pointBox">
                <div v-if="item.isSelect" class="active"></div>
                <div v-else-if="item.canOrder == 0" class="disable"></div>
                <div v-else class="noClick"></div>
              </div>
              <div :class="['time', { timeCanNot: item.canOrder == 0 }]">
                {{ item.beginTime + "~" + item.endTime }}
              </div>
            </div>
          </div>
          <div class="nodata" v-if="sessionsList.length === 0">暂无场次</div>
        </div>
        <!-- submit按钮 -->
        <div
          class="inbtnBox"
          v-if="sessionsList.length > 0"
          @click.stop="lastSubmit"
        >
          立即预约
        </div>
        <div class="emptyBox"></div>
      </div>
    </van-popup>
    <!-- bookingType === 1 排班预约 -->
    <v-dialog v-model="sessionsFlag" @confirm="confirm1">
      <div class="sessionsDialogBox">
        <div class="menTitle">参与人数:</div>
        <div class="numBox">
          <img src="./img/sub.png" class="sub" @click="numberChange('sub')" />
          <div class="num">{{ inMenNum }}</div>
          <img src="./img/add.png" class="add" @click="numberChange('add')" />
        </div>
        <div class="menTitle">预约说明:</div>
        <div class="messageBox">
          <textarea v-model="message" placeholder="请输入预约说明"></textarea>
        </div>
      </div>
    </v-dialog>
    <!-- bookingType === 2 电话预约 -->
    <v-dialog v-model="phoneFlag" title="提示" @confirm="confirm2">
      <div class="phoneDialogBox">
        <p>确认拨打电话：{{ areaDetail.bookingTel }}吗？</p>
      </div>
    </v-dialog>
    <!-- bookingType === 4 -->
    <v-dialog v-model="isDialog" title="请根据图文提示预约" @confirm="confirm4">
      <div class="dialogInfo">
        <div class="title">{{ areaDetail.bookingDesc }}</div>
        <div class="body">
          <div
            class="item"
            v-for="(item, index) in areaDetail.bookingImgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="video" v-if="areaDetail.bookingVideo != ''">
          <video
            id="video"
            :src="areaDetail.bookingVideo"
            controls="controls"
          ></video>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { toRegister } from "@/utils/common";
Vue.use(Swipe);
Vue.use(SwipeItem);
import {
  getAreaDetailUrl,
  getSessionUrl,
  getAppointUrl,
  placeTypeListUrl,
} from "./api.js";
export default {
  name: "activityDetail",
  data() {
    return {
      inMenNum: 1, //参与人数
      message: "", //预约说明

      isDialog: false, //bookingType  4
      areaDetail: {}, //场地详情

      nowDay: "",
      nowWeek: "",

      popupShow: false, //选择时间段遮罩层
      show: false, //日历展示
      now: new Date(),

      placeDate: "",
      placeId: "",
      scheduleIdList: [], //预约场次ID列表
      sessionsList: [], //当日所有场次列表
      phoneFlag: false, //电话预约 bookingType   2
      sessionsFlag: false, //排班预约 bookingType   1
      placeType: "",
    };
  },
  created() {
    this.placeId = this.$route.query.placeId;
    this.getDate(this.now);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    defOrgId() {
      return this.$store.state.defOrgId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  async mounted() {
    await this.getAreaDetail();
    await this.getPlaceTypeList();

    // this.getSessionsList()
  },
  methods: {
    async getPlaceTypeList() {
      let params = {
        categoryId: "1",
      };
      let res = await this.$axios.get(`${placeTypeListUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        res.data.forEach((item) => {
          if (item.id == this.areaDetail.placeTypeId) {
            this.placeType = item.typeName;
          }
        });
      }
    },
    callPeople() {
      window.location.href = `tel:${this.areaDetail.contactPhone}`;
    },
    // 最后提交按钮
    lastSubmit() {
      if (!this.sessionsList.length) {
        this.$toast({
          message: "暂无场次",
          duration: 300,
        });
        return;
      }
      if (!this.scheduleIdList.length) {
        this.$toast({
          message: "请选择场次",
          duration: 300,
        });
        return;
      }
      this.sessionsFlag = true;
    },
    // 参与人数变化
    numberChange(type) {
      if (type == "sub") {
        if (this.inMenNum == 1) {
          this.inMenNum == 1;
        } else {
          this.inMenNum--;
        }
      } else if (type == "add") {
        if (this.inMenNum == this.areaDetail.count) {
          this.inMenNum == this.areaDetail.count;
        } else {
          this.inMenNum++;
        }
      }
    },
    // 点击预约场次item触发
    selectSession(item, index) {
      if (item.canOrder == 0) {
        return;
      }
      item.isSelect = !item.isSelect;
      if (item.isSelect) {
        if (this.scheduleIdList.indexOf(item.id) == -1) {
          this.scheduleIdList.push(item.id);
        }
      } else {
        if (this.scheduleIdList.indexOf(item.id) != -1) {
          let oIndex = this.scheduleIdList.indexOf(item.id);
          this.scheduleIdList.splice(oIndex, 1);
        }
      }
      console.log(this.scheduleIdList, this.scheduleIdList.length);
    },
    // 日历选中触发
    select(value) {},
    onConfirm(date) {
      this.getDate(date);
      this.getSessionsList();
      this.show = false;
      this.popupShow = true;
    },
    // 打开日历组件
    async openCalendar() {
      if (this.areaDetail.bookingType == 1) {
        this.show = true;
      } else if (this.areaDetail.bookingType == 2) {
        this.phoneFlag = true;
      } else if (this.areaDetail.bookingType == 3) {
        window.location.href = `${this.areaDetail.bookingLinkUrl}`;
      } else if (this.areaDetail.bookingType == 4) {
        this.isDialog = true;
      }
    },
    // 关闭日历组件
    closeCalendar() {
      this.show = false;
    },
    // 外部立即预约按钮
    async showChoseSessios() {
      if (this.areaDetail.bookingType == 1) {
        this.getSessionsList();
        this.popupShow = true;
      } else if (this.areaDetail.bookingType == 2) {
        this.phoneFlag = true;
      } else if (this.areaDetail.bookingType == 3) {
        window.location.href = `${this.areaDetail.bookingLinkUrl}`;
      } else if (this.areaDetail.bookingType == 4) {
        this.isDialog = true;
      }
    },
    getSessionsList() {
      //获取预约场次列表
      let params = {
        placeDate: this.placeDate,
        placeId: this.placeId,
      };
      this.$axios
        .get(`${getSessionUrl}`, {
          params,
        })
        .then((res) => {
          console.log(res.data.length);
          if (res.code === 200) {
            this.sessionsList = [];
            res.data.forEach((element) => {
              element.isSelect = false;
              this.sessionsList.push(element);
            });
          } else {
          }
        });
    },
    getDate(d) {
      //日期格式化
      const year = d.getFullYear();
      const month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      const weekNum = d.getDay();
      this.nowDay = `${month}月${day}日`;
      this.placeDate = `${year}-${month}-${day}`;
      this.nowWeek = this.weekFormat(weekNum);
    },
    weekFormat(num) {
      //周几格式化
      if (num == 0) {
        return "周日";
      }
      if (num == 1) {
        return "周一";
      }
      if (num == 2) {
        return "周二";
      }
      if (num == 3) {
        return "周三";
      }
      if (num == 4) {
        return "周四";
      }
      if (num == 5) {
        return "周五";
      }
      if (num == 6) {
        return "周六";
      }
    },
    confirm4() {
      //bookingType === 4
      this.isDialog = false;
    },
    confirm2() {
      //电话预约
      window.location.href = `tel://${this.areaDetail.bookingTel}`;
    },
    confirm1() {
      //排班预约
      if (this.message == "") {
        this.$toast({
          message: "请填写预约说明",
          duration: 300,
        });
        return;
      }
      let params = {
        houseId: this.houseId || this.communityId || undefined,
        placeId: this.placeId,
        placeName: this.areaDetail.name,
        scheduleIdList: this.scheduleIdList,
        remark: this.message,
        bookingCnt: this.inMenNum,
        sourceType: 4,
        source: "1",
      };
      this.$axios
        .post(`${getAppointUrl}?userId=${this.userId}`, params)
        .then((res) => {
          this.sessionsFlag = false;
          this.inMenNum = 1;
          this.message = "";
          if (res.code === 200) {
            this.$router.replace({
              name: "appointSuccess",
            });
          } else {
          }
        });
    },
    async getAreaDetail() {
      //获取场地详情
      let params = {
        placeId: this.placeId,
      };

      let res = await this.$axios.get(`${getAreaDetailUrl}`, {
        params,
      });
      if (res.code === 200) {
        this.areaDetail = res.data;
        if (
          this.areaDetail.pictureBeanList &&
          this.areaDetail.pictureBeanList.length > 0
        ) {
          this.areaDetail.pictureBeanList.forEach((item) => {
            if (item.url && item.url.length > 0) {
              item.url = this.$handleImg(
                document.body.clientWidth * 2,
                Math.ceil(((document.body.clientWidth * 2) / 16) * 9),
                item.url
              );
            }
          });
        }
        if (this.areaDetail.bookingImg) {
          this.areaDetail.bookingImgList =
            this.areaDetail.bookingImg.split(",");
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.areaDetail {
  min-height: 100vh;
  background-color: #fafafa;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-top: 30px;
  padding-bottom: 200px;
  box-sizing: border-box;

  .calendarBox {
    width: 100%;
    height: 910px;
    position: fixed;
    top: 50px;
    z-index: 1000;

    .box {
      width: 694px;
      height: 910px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      margin: 0 auto;
    }
  }

  .popup_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;

    .inbtnBox {
      height: 66px;
      z-index: 10000000000000000;
      width: 550px;
      background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
      border-radius: 10px;
      text-align: center;
      line-height: 66px;
      font-size: 30px;
      color: #fff;
      margin: 0 auto;
      margin-top: 58px;
    }

    .emptyBox {
      height: 82px;
      width: 100%;
    }

    .bottom_box {
      width: 100%;
      overflow: hidden;
      overflow-y: auto;

      .nodata {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        color: #999;
      }

      .item_bg_change {
        background: rgba(0, 0, 0, 0.05);
      }

      .item {
        width: 688px;
        height: 104px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        margin-left: 32px;
        position: relative;

        .time {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 56px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.85);
        }

        .timeCanNot {
          color: rgba(0, 0, 0, 0.25);
        }

        .pointBox {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          .disable {
            width: 32px;
            height: 32px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }

          .active {
            box-sizing: border-box;
            width: 32px;
            border-radius: 50%;
            height: 32px;
            background: #ffffff;
            border: 2px solid #615dff;
            background: #5f5bff;
          }

          .noClick {
            box-sizing: border-box;
            width: 32px;
            border-radius: 50%;
            height: 32px;
            background: #ffffff;
            border: 2px solid #615dff;
          }
        }
      }
    }

    .popupTopBox {
      height: 220px;
      width: 100%;
      padding-top: 34px;
      padding-left: 36px;
      box-sizing: border-box;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);

      .name {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }

      .equip {
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
        margin-top: 16px;
      }

      .numBox {
        display: flex;
        align-items: center;
        margin-top: 16px;

        span {
          color: rgba(0, 0, 0, 0.5);
          font-size: 28px;
          margin-left: 12px;
        }

        img {
          width: 36px;
          height: 24px;
        }
      }
    }
  }

  .btnBox {
    width: 100%;
    position: fixed;
    bottom: 128px;

    .box {
      width: 550px;
      height: 66px;
      background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
      border-radius: 10px;
      text-align: center;
      line-height: 66px;
      font-size: 30px;
      color: #fff;
      margin: 0 auto;
    }
  }

  .bottomBox {
    width: 100%;
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
  }

  .activityDetail-t {
    .introImg {
      // height: 56.25vw;
      height: 330px;
      position: relative;
      width: 690px;
      margin-bottom: 30px;
      box-sizing: border-box;
      border-radius: 16px;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .detailBox {
    min-height: 228px;
    margin: 28px 30px 0px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding-top: 34px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
    box-sizing: border-box;

    .name {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }

    .equip {
      color: rgba(0, 0, 0, 0.5);
      font-size: 28px;
      margin-top: 16px;
    }

    .cdjs {
      display: block;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }

    .cdjs2 {
      width: 100%;
      /deep/video {
        width: 100% !important;
      }
      /deep/p {
        width: 100%;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        img {
          width: 100%;
        }
      }
    }

    .infoLine {
      display: flex;
      margin-bottom: 10px;

      div {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }

      span {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }

      .phoneBox {
        display: flex;
        align-items: center;

        img {
          margin-left: 8px;
          width: 26px;
        }
      }
    }

    .numBox {
      display: flex;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 28px;

      span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
        margin-left: 12px;
      }

      img {
        width: 36px;
        height: 24px;
      }
    }
  }

  .topBox {
    height: 92px;
    margin: 0 28px 40px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
    border-radius: 16px;
    box-sizing: border-box;
    position: relative;
    z-index: 1002;

    .date {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      position: absolute;
      top: 50%;
      left: 82px;
      transform: translateY(-50%);
    }

    .arrow {
      width: 26px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 314px;
      transform: translateY(-50%);
    }

    .timeIocn {
      width: 34px;
      height: 34px;
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translateY(-50%);
    }
  }

  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: bold;
    color: #323334;
    line-height: 44px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 8px;
      height: 32px;
      background: #fe6f16;
      border-radius: 4px;
    }
  }

  .sessionsDialogBox {
    width: 100%;
    height: 100%;
    padding: 64px 46px 0 46px;
    box-sizing: border-box;

    .messageBox {
      width: 100%;
      min-height: 184px;
      background: #ececec;
      border-radius: 16px;
      margin-bottom: 42px;

      textarea {
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        min-height: 184px;
        background-color: transparent;
        font-size: 30px;
      }
    }

    .menTitle {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
      margin-bottom: 24px;
    }

    .numBox {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .num {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        display: inline-block;
        margin: 0 8px;
        min-height: 48px;
        line-height: 48px;
        padding: 0 15px;
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        color: #151c39;
      }

      .sub,
      .add {
        width: 50px;
        height: 48px;
      }
    }
  }

  .phoneDialogBox {
    p {
      color: rgba(0, 0, 0, 0.5);
      font-size: 30px;
      text-align: center;
      margin-top: 26px;
      margin-bottom: 38px;
    }
  }

  .dialogInfo {
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;

    .title {
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }

    .body {
      .item {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .video {
      width: 100%;
      height: 400px;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
